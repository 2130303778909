import consumer from "./consumer"

const END_OF_ROUND = {}
const WAITING_FOR_NEXT_WORD = {}

let timer = 0
let timerRunning = false
let connected = false

let activeWord, nextWord, lastWord

function logSend(message, data) {
  log("🚀 "+message, data)
}

function logReceive(data) {
  log("📥 "+data.method, data.args)
}

function log(message, data) {
  if (window.location.href.indexOf(":3000") > 0) {
    let when = new Date()
    let ts = when.getHours()+":"+when.getMinutes()+":"+when.getSeconds()

    let string = ts + " " + message
    if (data) string += " | " + expand(data)
    console.log(string)
  }
}

function expand(args) {
  if (typeof args === "string") return args.substring(0,20)
  let inspect = "{"
  Object.entries(args).forEach((entry) => {
    let value
    if (typeof entry[1] === "object") {
      value = expand(entry[1])
    } else {
      value = entry[1].toString().substring(0,10)
    }
    inspect += entry[0] +": "+value+", "
  })
  return inspect.slice(0,-2) + "}"
}

function rightSize(element) {
  let parts = element.innerText.split(' ')
  let textCheck = document.getElementById('text-width-check')

  let longestWord = ''
  let longest = 0
  parts.forEach(word => {
    textCheck.innerText = word
    if (textCheck.clientWidth > longest) {
      longest = textCheck.clientWidth
      longestWord = word
    }
  })

  textCheck.style.fontSize = '5.5rem'
  textCheck.innerText = longestWord

  let viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  let viewHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

  let rem = 5.5
  while (rem > 2 && textCheck.clientWidth + 60 > viewWidth) {
    rem--
    textCheck.style.fontSize = rem + 'rem'
  }

  textCheck.innerText = element.innerText
  while (rem > 2 && textCheck.clientHeight > viewHeight / 3) {
    rem--
    textCheck.style.fontSize = rem + 'rem'
  }

  element.style.fontSize = rem + 'rem'
}

// noinspection JSUnusedGlobalSymbols - the methods in here are called dynamically #received (and other means)
window.gameStatus = consumer.subscriptions.create("GameStatusChannel", {
  connected() {
    log('connected')
    connected = true
    this.checkTurn()
  },

  disconnected() {
    connected = false
    log('disconnected')
  },

  //send
  checkTurn() {
    let pointsBoard = document.getElementById('points-board')
    if (pointsBoard) {
      this.perform("check_turn", pointsBoard.dataset)
    }
  },

  //send
  startTurn() {
    lastWord = null
    activeWord = null
    nextWord = WAITING_FOR_NEXT_WORD
    if (connected) {
      this.perform("start_turn")
    } else {
      this.showError("reload and try again")
    }
  },

  //send
  wordGuessed() {
    logSend("got", [activeWord, nextWord])

    let id = activeWord.id
    let nextId = nextWord.id

    lastWord = activeWord
    activeWord = nextWord
    nextWord = WAITING_FOR_NEXT_WORD

    if (activeWord === END_OF_ROUND) {
      this.celebrate()
    } else if (activeWord !== WAITING_FOR_NEXT_WORD) {
      this.showActiveWord()
    }
    this.perform("word_guessed", {id: id, nextId: nextId})
  },

  //receive
  runClock() {
    if (this.ensureInGame()) {
      timerRunning = true
      let clock = document.getElementById('clock')
      let stats = document.getElementById('stats')
      let guessing = document.getElementById('guessing')

      if (stats) stats.classList.add('d-none')
      if (guessing) guessing.classList.remove('d-none')

      let pointsBoard = document.getElementById('points-board')
      if (pointsBoard) document.querySelector('body').style.backgroundColor = pointsBoard.dataset.color

      let start = parseInt(clock.dataset.start)
      timer = parseFloat(clock.innerText)
      let end = (new Date()).getTime() / 1000 + timer

      requestAnimationFrame(tick)
      function tick() {
        timer = end - (new Date()).getTime() / 1000
        let nextUpdate = timer.toFixed(1)

        if (nextUpdate !== clock.innerText) {
          clock.innerText = nextUpdate
          let percent = (timer / start) * 100
          clock.style.width = percent + "%"
          if (percent < 40 && percent > 20) {
            clock.classList.add('bg-warning')
          } else if (percent < 20) {
            clock.classList.add('bg-danger')
          }
        }

        if (timer < 0.1) {
          timerRunning = false
          clock.innerText = '0.0'
          timer = start
        } else {
          requestAnimationFrame(tick)
        }

      }
    }
  },

  //private
  ensureInGame() {
    let continueButton = document.getElementById('continue')
    if (continueButton) {
      this.nav(continueButton.href)
      return false
    }
    return document.getElementById('clock') // no sense in running the clock if it doesn't exist
  },

  //private
  updateTeamTab(data, a, b) {
    let joinA = document.getElementById('join-'+a)
    let nameB = document.getElementById('game_team_'+b+'_name')
    if (data["team_"+a+"_name"] && joinA && nameB) {
      joinA.value = 'Join '+data["team_"+a+"_name"]
      joinA.style.backgroundColor = data["team_"+a+"_color"]
      let updatedTeamName = data["team_"+b+"_name"]
      if (nameB.dataset.original !== updatedTeamName) {
        nameB.dataset.original = updatedTeamName
        nameB.value = updatedTeamName
      }
    }
  },

  //receive
  updateLobby(data) {
    let roster = document.getElementById('roster')

    if (roster) {
      if (data.ready) {
        document.getElementById('ready-button').classList.remove('d-none')
        document.getElementById('waiting').classList.add('d-none')
      } else {
        document.getElementById('ready-button').classList.add('d-none')
        document.getElementById('waiting').classList.remove('d-none')
      }
      roster.innerHTML = data.update

      this.updateTeamTab(data, 0,1)
      this.updateTeamTab(data, 1,0)

    }
  },

  //receive
  guessedWord(guessed) {
    let word = document.getElementById('guessed-word')
    let gotIt = document.getElementById('got-it')
    if (word) {
      word.innerText = guessed
      rightSize(word)
    }

    if (gotIt) {
      gotIt.classList.remove('d-none')
      setTimeout(() => {gotIt.classList.add('d-none')}, 1000)
    }

  },

  //send
  undoGuess() {
    if (lastWord) {
      logSend("undo", lastWord)

      let id = lastWord.id
      let nextId = nextWord.id // will be empty if WAITING_FOR_NEXT_WORD or END_OF_ROUND

      nextWord = activeWord
      activeWord = lastWord
      lastWord = null

      this.perform("undo_guess", {id: id, nextId: nextId})
      this.showActiveWord()
    }
  },

  // private
  showActiveWord() {
    let activeWordBox = document.getElementById('active-word')
    if (activeWordBox && activeWord) {
      activeWordBox.innerText = activeWord.word
      rightSize(activeWordBox)

      if (activeWordBox.classList.contains('bg-dark')) {
        activeWordBox.classList.remove('bg-dark', 'text-white')
      } else {
        activeWordBox.classList.add('bg-dark', 'text-white')
      }

      document.getElementById('dance').classList.add('d-none')
      document.getElementById('word-guessed').disabled = false
    }
  },

  // private
  celebrate() {
    document.getElementById('dance').classList.remove('d-none')
    let wordBox = document.getElementById('active-word')
    wordBox.innerText = ''
    wordBox.classList.remove('bg-dark', 'text-white')
  },

  //receive
  newWords(data) {
    activeWord = data[0]
    if (data[1]) {
      nextWord = data[1]
    } else {
      nextWord = END_OF_ROUND
    }
    this.showActiveWord()
  },

  //receive
  newWord(data) {
    if (activeWord === WAITING_FOR_NEXT_WORD) {
      activeWord = data
      this.showActiveWord()
    } else {
      nextWord = data
    }
  },

  // receive
  noMoreWords() {
    if (activeWord === WAITING_FOR_NEXT_WORD) {
      this.celebrate()
    } else {
      nextWord = END_OF_ROUND
    }
  },

  //receive
  endTurn(path) {
    let bell = document.getElementById('times-up')
    if (bell) {
      document.getElementById('turn-over').classList.remove('d-none')
      document.getElementById('guessing').classList.add('d-none')
      bell.volume = 1
      bell.play()
      setTimeout(() => {window.location.href = path}, 2000)
    }
  },

  //receive
  showError(text) {
    let errorBox = document.getElementById('error-box')
    if (errorBox) {
      let message = document.createElement('p')
      message.innerText = "Oops, something went wrong: " + text
      errorBox.appendChild(message)
      errorBox.classList.remove('d-none')
    }
  },

  //receive
  updateClock(timeLeft) {
    if (!timerRunning) {
      this.runClock()
    }
    timer = parseFloat(timeLeft)
  },

  //receive
  nav(target) {
    window.location.href = target
  },

  received(data) {
    logReceive(data)
    if (data.method) {
      this[data.method](data.args)
    }
  }
})
