import Fireworks from "fireworks-canvas"

window.setCelebrationWords  = function(words) {
  let fireworksBox = document.getElementById('fireworks-box')
  if (fireworksBox) {
    const options = {
      maxRockets: 8,
      rocketSpawnInterval: 100,
      numParticles: 100,
      width: window.innerWidth,
      height: window.innerHeight
    }
    setTimeout(new Fireworks(fireworksBox, options).start(), 7000)
  }
}