import Rails from '@rails/ujs'

document.addEventListener("visibilitychange", () => {
    window.gameStatus.checkTurn()
})

document.addEventListener('keydown', (e) => {
    if (e.target.tagName === "INPUT") { return }
    if (e.key === " ") {
        let actionTaken = false
        let gameActions = document.querySelectorAll('.game-action')
        gameActions.forEach((action) => {
            if (actionTaken) return
            let chain = action.parentNode
            while (chain !== document) {
                if (chain.classList.contains('d-none')) {
                    break
                }
                chain = chain.parentNode
            }
            if (chain === document) {
                action.click()
                actionTaken = true
            }
        })
    } else if (e.key === "u") {
        let undoButton = document.getElementById('undo-guess')
        if (undoButton) undoButton.click()
    }
})

document.addEventListener("turbolinks:load", () => {
    let guessDebounce = null

    let keyboardHints = document.getElementById('keyboard-hints')
    if (keyboardHints) {
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            keyboardHints.classList.remove('d-none')
        }
    }

    document.querySelectorAll('.back').forEach((back) => {
        back.addEventListener('click', e => {
            e.preventDefault()
            history.back()
        })
    })

    if (!document.body.dataset.tz) {
        Rails.ajax({
            url: "/home/tz",
            type: "get",
            data: "offset="+(new Date()).getTimezoneOffset()
        })
    }

    document.querySelectorAll('.banked').forEach((banked) => {
        banked.addEventListener('click', (e) => {
            e.preventDefault()

            let word = e.target
            document.querySelectorAll('input.word-input').forEach(input => {
                if (!word.classList.contains('d-none') && input.value.length === 0) {
                    input.value = word.innerText
                    word.classList.add('d-none')
                }
            })
        })
    })

    let inviteLink = document.getElementById('invite-link')
    if (inviteLink) {
        inviteLink.addEventListener('click', (event) => {
            event.preventDefault()
            if (navigator.share) {
                navigator.share({
                    title: "Get Naked",
                    url: inviteLink.href
                })
            } else {
                navigator.clipboard.writeText(inviteLink.href).then(() => {
                    let classList = document.getElementById('copied-alert').classList
                    classList.remove('d-none')
                    setTimeout(()=>{classList.add('d-none')}, 1500)
                })
            }
        })
    }

    let dupeConfig = document.getElementById('duplicate-config')
    if (dupeConfig) {
        let dupeControl = document.getElementById('game_allow_similar_words')
        dupeControl.addEventListener('change', ev => {
            if (dupeControl.checked) {
                dupeConfig.classList.add('d-none')
            } else {
                dupeConfig.classList.remove('d-none')
            }
        })
    }

    let startButton = document.getElementById('start-button')
    if (startButton) {
        startButton.addEventListener('click', () => {
            startButton.disabled = true
            let bell = document.getElementById('times-up')
            bell.play()
            bell.pause()
            window.gameStatus.startTurn()
        })
    }

    let guessing = document.getElementById('guessing')
    if (guessing) {
        if (!guessing.classList.contains('d-none')) {
            window.gameStatus.runClock()
        }
    }

    let wordGuessedButton = document.getElementById('word-guessed')
    let undoButton = document.getElementById('undo-guess')
    if (wordGuessedButton) {
        wordGuessedButton.addEventListener('click', () => {
            if (guessDebounce && guessDebounce > new Date()) return
            wordGuessedButton.disabled = true
            guessDebounce = new Date()
            guessDebounce.setSeconds(guessDebounce.getSeconds()+1)
            let activeWordBox = document.getElementById('active-word')

            let oldWord = activeWordBox.innerText
            activeWordBox.innerText = '✓'
            undoButton.innerText = 'Undo "' + oldWord + '"'

            window.gameStatus.wordGuessed()
            undoButton.classList.remove('d-none')
        })

        undoButton.addEventListener('click', () => {
            undoButton.classList.add('d-none')
            guessDebounce = null
            window.gameStatus.undoGuess()
        })
    }
})
